<template>
  <div class="">
    <!-- 抽查结果详情 -->
      <div class="min-h-100vh bg-[#f8f8f8]">
        <my-nav title="任务抽查结果详情"> </my-nav>
        <div class="p-8px space-y-8px calc_height">
          <section v-for="(item, index) in pageData.detail" :key="index"
            class="p-15px bg-white rounded-8px text-[#222] text-16px">
            <div class="leading-24px pb-8px cus_border">{{item.checkConfigName}}</div>
            <div class="leading-24px">
              <span>抽查结果：</span>
              <span :class="[item.checkResult?'text-[#28C469]':'text-[#F44A45]']">
                {{item.checkResult === 1?'合格':'不合格'}}
              </span>
            </div>
            <div class="leading-24px mt-8px" v-if="item.checkConclusion">
              <span>抽查结论：</span>
              <span>{{item.checkConclusion||'-'}}</span>
            </div>
            <div class="mt-16px inline-flex flex-wrap gap-x-20px gap-y-12px"
              v-if="item.attachments.length">
              <van-image v-for="(imgItem, idx) in item.attachments" :key="idx"
                @click="imgPreview(idx,item.attachments)" width="48" height="48" radius="4"
                :src="imgItem.url" />
            </div>
          </section>
        </div>
      </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
export default {
  name: 'ResultDetails',
  data () {
    return {
      imagesGroup: [],
      aa: true,
      pageData: {
        detail: []
      }
    }
  },
  created () {},
  mounted () {
    const { pageData } = this.$route.query
    this.pageData = pageData
  },
  methods: {
    imgPreview (index, attachmentList) {
      this.imagesGroup = []
      attachmentList &&
        attachmentList.forEach((item) => {
          this.imagesGroup.push(item.url)
        })
      ImagePreview({
        images: this.imagesGroup,
        startPosition: index
      })
    }
  },
  computed: {},
  watch: {}
}
</script>

<style lang="less" scoped>
.icon-back {
  height: 24px;
  width: 24px;
  background: url("../../../asset/icon_back_black@2x.png") no-repeat;
  background-size: cover;
}
.cus_border {
  border-bottom: thin solid #f5f5f5;
  margin-bottom: 12px;
}
.calc_height {
  height: calc(100vh - 45px);
  overflow-y: auto;
}
</style>
