var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "min-h-100vh bg-[#f8f8f8]" },
      [
        _c("my-nav", { attrs: { title: "任务抽查结果详情" } }),
        _c(
          "div",
          { staticClass: "p-8px space-y-8px calc_height" },
          _vm._l(_vm.pageData.detail, function (item, index) {
            return _c(
              "section",
              {
                key: index,
                staticClass:
                  "p-15px bg-white rounded-8px text-[#222] text-16px",
              },
              [
                _c("div", { staticClass: "leading-24px pb-8px cus_border" }, [
                  _vm._v(_vm._s(item.checkConfigName)),
                ]),
                _c("div", { staticClass: "leading-24px" }, [
                  _c("span", [_vm._v("抽查结果：")]),
                  _c(
                    "span",
                    {
                      class: [
                        item.checkResult ? "text-[#28C469]" : "text-[#F44A45]",
                      ],
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(item.checkResult === 1 ? "合格" : "不合格") +
                          " "
                      ),
                    ]
                  ),
                ]),
                item.checkConclusion
                  ? _c("div", { staticClass: "leading-24px mt-8px" }, [
                      _c("span", [_vm._v("抽查结论：")]),
                      _c("span", [_vm._v(_vm._s(item.checkConclusion || "-"))]),
                    ])
                  : _vm._e(),
                item.attachments.length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "mt-16px inline-flex flex-wrap gap-x-20px gap-y-12px",
                      },
                      _vm._l(item.attachments, function (imgItem, idx) {
                        return _c("van-image", {
                          key: idx,
                          attrs: {
                            width: "48",
                            height: "48",
                            radius: "4",
                            src: imgItem.url,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.imgPreview(idx, item.attachments)
                            },
                          },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }